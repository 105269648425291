body {
  overflow: auto;
  overflow-x: hidden;
  color: #15171a;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
.minvh-100 {
  min-height: 100vh;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.3em;
  color: #15171a;
  line-height: 1.3em;
  text-rendering: optimizeLegibility;
}
.fs-14 {
  font-size: 14px;
}
.fs-13 {
  font-size: 13px;
}

.gh-app {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  height: 100%;
}
.gh-view-port {
  flex-grow: 1;
  max-height: 100%;
}
.gh-app,
.gh-view-port {
  display: flex;
  overflow: hidden;
}
.gh-nav {
  position: relative;
  z-index: 800;
  flex: 0 0 260px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  transform: translateX(0);
  border-right: 1px solid #e6e9eb;
  transition: flex-basis 0.25s;
  overflow: hidden;
}
.gh-main {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.gh-nav-menu {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 96px;
  padding: 32px;
  padding-left: 40px;
}
.gh-nav-menu-details {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-right: 10px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-size: 15px;
  letter-spacing: 0;
  line-height: 13px;
  font-weight: 700;
  color: #15171a;
}
.gh-nav-logo {
  height: 20px;
}
.gh-nav-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: auto;
  padding: 0;
}

.gh-nav-list:first-of-type {
  margin-top: 0;
}

.gh-nav-main {
  margin: 24px 0;
}
.gh-nav-list {
  margin: 32px 0 0;
  padding: 0;
  list-style: none;
  font-size: 14px;
  line-height: 16px;
}
.gh-nav-list li {
  margin: 0;
  padding: 0;
}
.gh-nav-list a,
.gh-nav-list button.main-menu-item {
  display: flex;
  align-items: center;
  color: #40474f;
  transition: none;
  font-weight: 400;
  padding: 7px 32px 7px 39px;
  font-size: 14px;
  height: 34px;
  margin: 0;
  border-radius: 0;
  box-sizing: border-box;
}
.gh-nav-list svg {
  margin-right: 17px;
  width: 16px;
  height: 16px;
  line-height: 1;
  transition: none;
  z-index: 999;
}
.gh-nav-list a:not(.active):hover,
.gh-nav-list button.main-menu-item:hover {
  color: #394047;
  background: #f1f3f4;
  opacity: 1;
}
.gh-nav-list .active {
  position: relative;
  color: #15171a;
  font-weight: 700;
}
.gh-canvas {
  flex-grow: 1;
  padding: 0 48px 48px;
  margin: 0 auto;
}
.gh-canvas-header {
  min-height: 95px;
  padding: 32px 0;
  top: 0;
  background: #fff;
  z-index: 700;
  border-bottom: 1px solid;
  border-color: transparent;
  position: relative;
}
.gh-canvas-header-content {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.gh-canvas-header .gh-canvas-breadcrumb {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 34px;
  color: #8e9cac;
  font-size: 14px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0.2px;
  background: none;
  white-space: nowrap;
  text-decoration: none;
}
.gh-canvas-header .gh-canvas-breadcrumb svg path {
  stroke: #8e9cac;
}
.gh-canvas-header .gh-canvas-breadcrumb a {
  color: #394047;
  line-height: 24px;
  text-decoration: none;
}
.gh-canvas-header .gh-canvas-breadcrumb svg {
  display: block;
  width: 9px;
  height: 9px;
  margin: 1px 6px 0;
}
.gh-canvas-title {
  z-index: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: -3px 0 0;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 28px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: -0.021em;
  min-height: 35px;
  color: #15171a;
}
.gh-canvas-title a {
  color: #15171a;
  text-decoration: none;
}
.view-actions,
.view-actions-secondary {
  display: flex;
  align-items: center;
}

.view-actions {
  flex-shrink: 0;
}
.fw-500 {
  font-weight: 500;
}
.fw-bold {
  font-weight: bold;
}

/* btn */
.btn {
  display: block;
  overflow: hidden;
  padding: 0 14px;
  height: 38px;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.2px;
  border-radius: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}
.btn-lg {
  padding: 0 24px;
  height: 50px;
  font-size: 16px;
  line-height: 20px;
}
.btn-dark {
}
.btn-disabled,
.btn-disabled:hover {
  background: #f5f6f6;
  color: #727272;
  cursor: not-allowed !important;
}
.btn-link {
  border: none;
  background: none !important;
  text-decoration: underline !important;
  text-transform: none !important;
}
.gh-main-section-content.grey {
  background: #f5f6f6;
  padding: 24px;
  border-radius: 3px;
}
.gh-main-section-content {
  display: grid;
  grid-template-columns: 1fr;
}
form label {
  display: block;
  color: #15171a;
  font-size: 13px;
  font-weight: 500;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}
.form-control,
.form-control,
select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  border: 1px solid #dddedf;
  background: #fff;
  color: #394047;
  font-size: 15px;
  letter-spacing: 0;
  font-weight: 400;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  border-radius: 3px;
  -webkit-appearance: none;
}
.form-select {
  border-radius: 3px;
  border: 1px solid #dddedf;
  background: #fff;
}
select {
  appearance: auto !important;
  -webkit-appearance: auto !important;
}
.editor-wrap {
  padding: 6px 12px;
  border: 1px solid #dddedf;
  background: #fff;
  border-radius: 3px;
}
.select__control {
  border-color: #dddedf !important;
  background: #fff;
  color: #394047 !important;
  border-radius: 3px !important;
  font-size: 15px;
}
textarea {
  width: 100%;
  height: auto;
  min-width: 250px;
  min-height: 150px;
  line-height: 1.5em;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  resize: vertical;
}
textarea.form-control {
  min-height: 150px;
}

.edit-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
}
.edit-icon svg {
  width: 16px;
  height: 16px;
}

.loader-wrap {
  width: 100%;
  height: 300px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.filter-box {
  /* border: 1px solid #dee2e6; */
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 30px;
}

.drag-wrap{
  background-color: #f8f9fa;
  padding: 40px;
}
.drag-item {
  border-radius: 5px;
  overflow: hidden;
  width: 120px;
  height: 180px;
  display: inline-block;
  margin: 4px;
}

/* other old */
.feather {
  width: 20px;
  height: 20px;
  vertical-align: text-bottom;
}
.sidebar .nav-link .feather {
  margin-right: 6px;
  color: #727272;
}
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}
.sidebar .nav-link {
  font-weight: 400;
  color: #40474f;
  font-size: 14px;
}
.sidebar .nav-link.active {
  color: #2470dc;
}
.sidebar ul ul {
  list-style: none;
  padding-left: 27px;
  margin-bottom: 5px;
}
.sidebar ul ul .nav-link {
  /* font-weight: normal; */
  font-size: 14px;
}

.btn-link {
  text-transform: uppercase;
  text-decoration: none;
}
.btn-group-sm > .btn,
.btn-sm {
  font-size: 12px;
  font-weight: 500;
  padding-top: 1px;
  padding-bottom: 1px;
  --bs-btn-padding-y: 0.3rem;
}
/* loaders */
.spinner-border-wrap {
  z-index: 55;
}
.btn .spinner-border-wrap {
  width: auto !important;
  margin-right: 10px;
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid #212529;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border.light {
  border: 0.25em solid #e4e4e4;
  border-right-color: transparent;
}
.spinner-border.small {
  border-width: 0.12em;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.loader-bg {
  background-color: #ffffff9d;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.photo-thumb {
  width: 100px;
}
.photo-thumb img {
  width: 100%;
  height: auto;
}
.photo-thumb.featured {
  outline: 2px solid #2470dc;
}

.photo-thumb-wrap {
  position: relative;
}
.thumb-remove {
  position: absolute;
  right: 12px;
  top: 3px;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  opacity: 0;
  background-color: red;
  color: #fff;
}
.photo-thumb-wrap:hover .thumb-remove {
  opacity: 1;
}

.product-thumb21-wrap {
  display: block;
}
.product-thumb21-wrap .product-thumb {
  width: 40px;
  display: inline-block;
}
.product-thumb21-wrap .product-thumb img {
  width: 100%;
}
.product-thumb21-wrap .product-thumb.featured {
  outline: 2px solid #2470dc;
}

.product-list-thumb {
  width: 80px;
}
.product-list-thumb.small {
  width: 30px;
}

.table {
  color: #15171a;
  font-size: 14px;
}
.table th {
  font-weight: 500;
  font-size: 13px;
}
.table > :not(caption) > * > * {
  padding: 10px 1.5rem;
}

.pos-abs {
  position: absolute;
}
.pos-abs.filled {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 90;
}
.bg-loader {
  opacity: 0.8;
  background-color: #fff;
}
.lastDiv {
  min-height: 300px;
}
.empty-box {
  font-size: 14px;
  text-align: center;
  color: #727272;
  padding: 40px;
}

.dash-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.dash-row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.dash-col {
  flex: 1 0 0%;
}

.dash-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.dash-box {
  border: 1px solid #e6e9eb;
  padding: 20px;
  border-radius: 6px;
  display: block;
  text-decoration: none;
  color: #40474f;
}
.db-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.dash-box p {
  margin-bottom: 0;
}

/* modal */
.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #f5f6f6;
  opacity: 0.6;
}
.modal-backdrop.locked {
  cursor: not-allowed;
}
.modal.dark .modal-backdrop {
  background-color: #15171a;
}
.modal.dark .modal-dialog {
  -webkit-box-shadow: 0px 1px 10px 0px var(--bg-color-secondary);
  -moz-box-shadow: 0px 1px 10px 0px var(--bg-color-secondary);
  box-shadow: 0px 1px 10px 0px var(--bg-color-secondary);
}
.modal-root {
  z-index: 101;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-dialog {
  outline: none;
  min-width: 410px;
  max-width: 410px;
  z-index: 108;
  position: relative;
  top: 0%;
  box-sizing: border-box;
  /* max-height: 873px; */

  /* initial */
  z-index: 3000;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--radius-medium);
  background-color: var(--bg-color-white);

  /* margin-top: 120px; */
  margin-left: auto;
  margin-right: auto;
  overflow: initial;
  /* height: 620px; */
  /* min-height: 188px; */
  color: var(--color-core);
  -webkit-box-shadow: 0px 1px 10px 0px var(--bg-color-darkgray);
  -moz-box-shadow: 0px 1px 10px 0px var(--bg-color-darkgray);
  box-shadow: 0px 1px 10px 0px var(--bg-color-darkgray);
}

.modal-dialog {
  min-width: 500px;
  max-width: 500px;
}
.modal-dialog.medium {
  min-width: 620px;
  max-width: 620px;
}
.modal-dialog.full {
  max-width: 100%;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 30px;
}
.modal-dialog.full .modal-content {
  height: 100%;
  max-height: 100%;
}
.modal-dialog.full .modal-body {
  height: 100%;
  max-height: 100%;
  padding: 0;
}
.modal-dialog.log {
  max-width: 80%;
  height: 100%;
  max-height: 80%;
}
.modal-dialog.log .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal-content {
  position: relative;
}
.modal-body {
  padding: 50px 60px;
}
.dialog-title {
  font-size: 21px;
}

.media-list-wrap {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  gap: 20px;
}
.media-item {
  aspect-ratio: 1 / 1;
  border: 1px solid #dddedf;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.media-item.selected {
  outline: 3px solid #2470dc;
}
.media-item.delete-selected {
  outline: 3px solid #ec6d6d;
}
.media-pp-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.media-pp-head {
  min-height: 60px;
  padding: 15px 20px 10px 20px;
  border-bottom: 1px solid #dddedf;
}
.media-pp-toolbar {
  min-height: 80px;
  padding: 15px 20px 10px 20px;
  border-top: 1px solid #dddedf;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.media-pp-list-wrap {
  overflow-y: auto;
  padding: 20px;
}
.media-lib-link {
  display: inline-block;
}
.thumb-prev {
  max-width: 200px;
}

.thumb-prev img {
  width: 100%;
}

.avatar-md {
  height: 5rem;
  width: 5rem;
}

.fs-19 {
  font-size: 19px;
}

.primary-link {
  color: #314047;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* post list item */
.product-post .favorite-icon a {
  background-color: #da3746;
  color: #fff;
  border-color: danger;
}
.favorite-icon a {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #eff0f2;
  border-radius: 6px;
  color: rgba(173, 181, 189, 0.55);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.candidate-list-box .favorite-icon {
  position: absolute;
  right: 22px;
  top: 22px;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}
.bg-soft-secondary {
  background-color: rgba(116, 120, 141, 0.15) !important;
  color: #74788d !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}
.currency-form-control {
  width: 60px;
}
.price-form-control {
  width: 120px;
}


.product-list-item{
  border-bottom: 1px solid #dee2e6;
}